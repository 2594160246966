import { useMutation, useQuery } from '@apollo/client';
import { PageHeader, Row, Card, Col, Button, Form, Input, Select, DatePicker, message, Switch, Modal, Upload, Typography, InputNumber, Tag, Space, Alert } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { SUB_EVENT_PLAYER } from '../../../../apollo/query/sub-event';
import BadResponse from '../../../../components/BadResponse';
import CircularProgress from '../../../../components/CircularProgress';
import { DATE_FORMAT, TRACKING_MODE } from '../../../../constants/App';
import IntlMessages from '../../../../util/IntlMessages';
import { getAdminPath, getMediaURL, pushNextOrFallback } from '../../../../util/router';
import {
    UnorderedListOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    EyeOutlined,
    InboxOutlined,
    SaveOutlined,
    UploadOutlined,
    LoadingOutlined,
    SwapOutlined,
    DeleteOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { emailRule, requiredRule } from '../../../../util/form';
import LocationSelect from '../../../../components/LocationSelect';
import CustomField from '../../../../components/CustomField';
import EmptyCard from '../../../../components/EmptyCard';
import { subEventHasAthleteCard, subEventHasMedicalCartificate } from './common';
import { first, get, keys } from 'lodash';
import DevButton from '../../../../components/Dev/DevButton';
import {
    DELETE_PLAYER_MEDICAL_CERTIFICATE,
    MOVE_PLAYER,
    PLAYER_ATHLETE_CARD_VALIDATIONS,
    PLAYER_SUBSCRIPTION_CONFIRMATIONS,
    UPDATE_PLAYER,
} from '../../../../apollo/mutation/sub-event';
import { defaultCatchException, defaultMutationCallback, defaultMutationHandler } from '../../../../apollo/callbacks';
import SubEventBreadcrumbs from '../breadcrumbs';
import { AthleteCardValidationTag } from '../../../../components/Icons/AthleteCardValidation';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert';
import CountrySelect from '../../../../components/LocationSelect/CountrySelect';
import { getSafeISOLanguage } from '../../../../lngProvider';

const AssetUploadModal = ({ data, visible, onCancel, refetch, title }) => {
    const intl = useIntl();

    const { Dragger } = Upload;

    return (
        <Modal title={title} visible={visible} onCancel={onCancel} onOk={onCancel}>
            <Dragger
                multiple={false}
                onChange={(info) => {
                    const { status } = info.file;

                    if (status !== 'uploading') {
                        console.log(info.file, info.fileList);
                    }

                    if (status === 'done') {
                        message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                        refetch(); //leggero timeout prima di chiudere la modale??
                        onCancel();
                    } else if (status === 'error') {
                        const serverMessage = get(info, 'file.response.errors.image.0', null);
                        if (serverMessage) {
                            message.error(serverMessage);
                        } else {
                            message.error(intl.formatMessage({ id: 'uploads.file_upload_help' }, { mime: 'jpeg, png, pdf', size: '8MB' }));
                        }
                    }
                }}
                data={data}
                action={`${process.env.REACT_APP_DATA_URL}/api/certificate/upload?locale=${intl.locale}`}
                // TODO CHECK AUTH
                // headers={{
                //     Authorization: `Bearer ${getAuthToken()}`,
                // }}
                accept="image/png, image/jpeg, application/pdf"
                placeholder={<IntlMessages id="common.upload" />}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{<IntlMessages id="uploads.dragger_upload_file" />}</p>
                <p className="ant-upload-hint">
                    <IntlMessages id="uploads.file_upload_help" values={{ mime: 'jpeg, png, pdf', size: '8MB' }} />
                </p>
            </Dragger>
        </Modal>
    );
};

//TODO se già cancellato delete hard altrimenti soft
const MovePlayerModal = ({ visible, subEvent, player, onCancel, onSuccess, intl }) => {
    const [movePlayer, { loading: deleteLoading }] = useMutation(MOVE_PLAYER);

    const targets = subEvent.event.sub_events.filter((s) => s.id !== player.sub_event?.id);

    const [targetSubEvent, setTargetSubEvent] = useState(first(targets)?.id || null);

    return (
        <Modal
            visible={visible}
            title={<IntlMessages id="common.move_athlete_to_race" />}
            onCancel={onCancel}
            okButtonProps={{ loading: deleteLoading }}
            onOk={() => {
                movePlayer({
                    variables: {
                        id: player.id,
                        sub_event_id: targetSubEvent,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: () => {
                                onSuccess();
                            },
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            }}
        >
            <BadResponseErrorAlert className="gx-mb-3" />

            <IntlMessages id="common.move_athlete_to_race_text_v2" />

            <div className="gx-mt-4">
                <Typography.Text className="gx-d-block gx-my-2">
                    <IntlMessages id={`common.event_type_${subEvent.event.type}`} />
                </Typography.Text>

                <Select
                    value={targetSubEvent}
                    style={{ minWidth: 300 }}
                    onChange={(value) => {
                        setTargetSubEvent(value);
                    }}
                >
                    {targets.map((target) => {
                        return <Select.Option value={target.id}>{target.name}</Select.Option>;
                    })}
                </Select>
            </div>
        </Modal>
    );
};

const CustomFieldsCard = ({ subEvent, intl }) => {
    if (!subEvent.custom_fields || !subEvent.custom_fields.length) {
        return <EmptyCard title={<IntlMessages id="common.custom_fields" />} />;
    }

    const { main_language: language } = subEvent.event;

    return (
        <Card title={<IntlMessages id="common.custom_fields" />} className="gx-card">
            {subEvent.custom_fields.map((field) => {
                return (
                    <Form.Item label={field.label[language]} name={['custom_field', field.name]} rules={field.required ? [requiredRule(intl)] : null}>
                        <CustomField field={field} language={language} />
                    </Form.Item>
                );
            })}
        </Card>
    );
};

const ProductsCard = ({ variants, subEvent }) => {

    if (!variants || !variants.length) {
        return <EmptyCard size="sm" title={<IntlMessages id="products.products" />} />;
    }

    return (
        <Card title={<IntlMessages id="products.products" />} className="gx-card">

            <Alert  showIcon type="info" className='gx-mb-4' message={<IntlMessages id="products.change_variant_not_repriced_tip"/>} />

            {(subEvent.event?.products || []).map((product) => {
                return (
                    <Form.Item label={product.name} name={['product_variants', product.id]}>
                        <Select>
                            {product.variants.map((variant) => {                                

                                return (
                                    <Select.Option value={variant.sku} disabled={variant.available_quantity <= 0}>
                                        {variant.attributes.map((attr, index) => {
                                            return (
                                                <span key={index}>
                                                    {attr.attribute_name_locale}
                                                    <Tag className="gx-mx-2">{attr.option_name_locale}</Tag>
                                                </span>
                                            );
                                        })}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                );
            })}
        </Card>
    );
}

const formValuesToMutation = (values) => {
    const { custom_field, ...rest } = values;

    return {
        ...rest,
        birthday: values.birthday.format('YYYY-MM-DD'),
        custom_fields: keys(custom_field).map((key) => {
            return {
                key: key,
                value: custom_field[key],
            };
        }),
        product_variants: keys(values.product_variants).map((key) => {
            return {
                key: key,
                value: values.product_variants[key],
            };
        }),
    };
};

const UpdateSubEventPlayer = ({ match }) => {
    const intl = useIntl();

    const locale = getSafeISOLanguage(intl.locale);

    const [updatePlayer, { loading: updateLoading }] = useMutation(UPDATE_PLAYER);
    const [deletePlayerMedicalCertificate, { loading: deleteCertificateLoading }] = useMutation(DELETE_PLAYER_MEDICAL_CERTIFICATE);
    const [subscriptionConfimations, { loading: confirmationLoading }] = useMutation(PLAYER_SUBSCRIPTION_CONFIRMATIONS);
    const [athleteCardValidations, { loading: athleteCardValidationLoading }] = useMutation(PLAYER_ATHLETE_CARD_VALIDATIONS);
    const [showCertificateUploadModal, setShowCertificateUploadModal] = useState(false);
    const [showCardUploadModal, setShowCardUploadModal] = useState(false);
    const [showMovePlayer, setShowMovePlayer] = useState(false);

    const { params } = match;

    const history = useHistory();

    const { data, loading, error, refetch } = useQuery(SUB_EVENT_PLAYER, {
        variables: {
            playerId: params.player_id,
            subEventId: params.id,
        },
        context: {
            headers: {
                'x-locale': locale,
            },
        },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    if (!data || !data.subEvent || !data.player) {
        return <Redirect to={getAdminPath(`event/${params.event_id}/sub-event/${params.id}`)} />;
    }

    const { subEvent, player } = data;

    const onSubscriptionConfirmation = (isConfirmed) => {
        const textKey = isConfirmed ? 'common.confirm_subscription' : 'common.unconfirm_subscription';

        Modal.confirm({
            title: intl.formatMessage({ id: textKey }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: confirmationLoading,
            },
            onOk: () => {
                subscriptionConfimations({
                    variables: {
                        input: [
                            {
                                id: params.player_id,
                                confirmed: isConfirmed,
                            },
                        ],
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successMessage: intl.formatMessage({ id: 'common.operation_successful' }),
                            successCallback: () => {
                                history.push(getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscriber/view/${player.id}`));
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    };

    const onDeleteCertificate = (e) => {
        e.preventDefault();
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.confirm' }),
            content: intl.formatMessage({ id: 'common.modal_delete_medical_certificate' }),
            okText: intl.formatMessage({ id: 'common.yes' }),
            cancelText: intl.formatMessage({ id: 'common.no' }),
            okButtonProps: {
                loading: deleteCertificateLoading,
            },
            onOk: () => {
                deletePlayerMedicalCertificate({
                    variables: {
                        id: player.id,
                    },
                })
                    .then(({ data, errors }) => {
                        if (!errors) {
                            message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                            refetch();
                        } else {
                            message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                        }
                    })
                    .catch((error) => {
                        defaultCatchException(error, intl);
                    });
            },
        });
    };

    const onSubmit = (input) => {
        updatePlayer({
            variables: {
                id: player.id,
                input: {
                    ...input,
                    certificate_expire_at: input.certificate_expire_at ? input.certificate_expire_at.format('YYYY-MM-DD') : null,
                },
            },
        })
            .then((response) => defaultMutationCallback(response, () => closeHandler(response.data)))
            .catch((e) => defaultCatchException(e, intl));
    };

    const closeHandler = () => {
        pushNextOrFallback(history, getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscribers`));
    };

    return (
        <>
            <Form
                onFinish={(values) => {
                    const input = formValuesToMutation(values);

                    if (
                        input.tracking_number &&
                        player.tracking?.number &&
                        player.tacking?.assignment_mode !== TRACKING_MODE.MANUAL &&
                        input.tracking_number !== player.tracking.number
                    ) {
                        Modal.confirm({
                            title: intl.formatMessage({ id: 'tracking.subscription_confirm_bib_number_change' }),
                            okText: intl.formatMessage({ id: 'common.confirm' }),
                            cancelText: intl.formatMessage({ id: 'common.cancel' }),
                            onOk: () => {
                                onSubmit(input);
                            },
                        });
                        return;
                    } else {
                        onSubmit(input);
                    }
                }}
                layout="vertical"
                initialValues={{
                    first_name: player.first_name,
                    last_name: player.last_name,
                    gender: player.gender,
                    birthday: player.birthday ? moment(player.birthday, 'YYYY-MM-DD') : null,
                    email: player.email,
                    phone: player.phone,
                    citizenship: player.citizenship,
                    location: player.location?.id,
                    custom_field:
                        player.custom_fields && player.custom_fields.length > 0
                            ? player.custom_fields.reduce((acc, cur) => {
                                acc[cur.name] = cur.value;
                                return acc;
                            }, {})
                            : null,
                    product_variants: player.product_variants.reduce((acc, cur) => {
                        acc[cur.product.id] = cur.sku
                        return acc;
                    }, {}),

                    athlete_card: player.athlete_card ? player.athlete_card.value : null,
                    athlete_card_number: player.athlete_card ? player.athlete_card.number : null,
                    athlete_card_provider_organization_name: player.athlete_card ? player.athlete_card.organization_name : null,
                    athlete_card_provider_organization_code: player.athlete_card ? player.athlete_card.organization_code : null,
                    certificate_expire_at: player.certificate?.expire_at ? moment(player.certificate.expire_at, 'YYYY-MM-DD') : null,
                    certificate_organization_confirm: player.certificate?.organization_confirm,
                    retired: player.retired || false,
                    organization_note: player.organization_note || '',
                    tracking_number: player.tracking?.number || null,
                }}
                labelCol={{ span: 20 }}
                wrapperCol={{ span: 20 }}
            >
                <Row>
                    <Col md={24}>
                        <SubEventBreadcrumbs subEvent={subEvent} section={<IntlMessages id={`common.players`} />} />
                    </Col>
                </Row>

                <Row>
                    <Col xs={24}>
                        <PageHeader
                            onBack={() => history.goBack()}
                            title={
                                <span>
                                    <IntlMessages id="common.player" />
                                    &nbsp;|&nbsp;{subEvent.name}
                                </span>
                            }
                            extra={
                                <Space wrap>
                                    <Link key="list" className="ant-btn" to={getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscribers`)}>
                                        <UnorderedListOutlined /> <IntlMessages id="common.subscribers_table" />
                                    </Link>

                                    <Link
                                        key="view"
                                        className="ant-btn"
                                        to={getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscriber/view/${player.id}`)}
                                    >
                                        <EyeOutlined /> <IntlMessages id="common.view" />
                                    </Link>

                                    {subEvent.event.sub_events?.length > 1 ? (
                                        <Button key="move" type="dashed" className="gx-btn-info" onClick={() => setShowMovePlayer(true)}>
                                            <SwapOutlined /> <IntlMessages id="common.change_athlete_race" />
                                        </Button>
                                    ) : null}
                                    {player.confirmed ? (
                                        <Button
                                            type="dashed"
                                            className="gx-btn-danger"
                                            icon={<CloseOutlined className="gx-pr-1" />}
                                            key="confirm"
                                            onClick={() => onSubscriptionConfirmation(false)}
                                        >
                                            <IntlMessages id="common.unconfirm_subscription" />
                                        </Button>
                                    ) : (
                                        <Button
                                            type="dashed"
                                            className="gx-btn-info"
                                            icon={<CheckOutlined className="gx-pr-1" />}
                                            key="confirm"
                                            onClick={() => onSubscriptionConfirmation(true)}
                                        >
                                            <IntlMessages id="common.confirm_subscription" />
                                        </Button>
                                    )}
                                    <Button loading={updateLoading} key="update" icon={<SaveOutlined className="gx-pr-1" />} type="primary" htmlType="submit">
                                        <IntlMessages id="common.save" />
                                    </Button>
                                </Space>
                            }
                        />
                    </Col>

                    <Col xs={24} md={24}>
                        <BadResponseErrorAlert />
                    </Col>

                    <Col xs={24} md={24} lg={12}>
                        <Card title={<IntlMessages id="common.registry" />} className="gx-card">
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'common.field_required',
                                        }),
                                    },
                                ]}
                                label={<IntlMessages id="common.first_name" />}
                                name="first_name"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item rules={[requiredRule(intl)]} label={<IntlMessages id="common.last_name" />} name="last_name">
                                <Input />
                            </Form.Item>
                            <Form.Item name="gender" label={<IntlMessages id="common.gender" />}>
                                <Select>
                                    <Select.Option value="m">
                                        <IntlMessages id="common.male" />
                                    </Select.Option>
                                    <Select.Option value="f">
                                        <IntlMessages id="common.female" />
                                    </Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name="birthday" label={<IntlMessages id="common.birthday" />}>
                                <DatePicker format={DATE_FORMAT} />
                            </Form.Item>

                            <Form.Item name="citizenship" trigger="onSelect" label={<IntlMessages id="common.citizenship_country" />}>
                                <CountrySelect />
                            </Form.Item>

                            <Form.Item
                                rules={subEvent.predefined_fields?.location.required ? [requiredRule(intl)] : []}
                                trigger="onSelect"
                                name="location"
                                label={<IntlMessages id="common.location" />}
                            >
                                <LocationSelect />
                            </Form.Item>
                        </Card>

                        <Card title={<IntlMessages id="common.subscription" />} className="gx-card">
                            <Form.Item rules={[requiredRule(intl), emailRule(intl)]} name="email" label={<IntlMessages id="common.email" />}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                rules={subEvent.predefined_fields?.phone.required ? [requiredRule(intl)] : []}
                                name="phone"
                                label={<IntlMessages id="common.phone" />}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                extra={[
                                    player.tracking?.assignment_mode ? (
                                        <span className="gx-pt-2">
                                            <IntlMessages id="common.tracking_mode" />:{' '}
                                            <Tag>
                                                <IntlMessages id={`tracking.${player.tracking?.assignment_mode.toLowerCase()}`} />
                                            </Tag>
                                        </span>
                                    ) : null,
                                ].filter(Boolean)}
                                label={<IntlMessages id="common.bib_number" />}
                                name="tracking_number"
                            >
                                <InputNumber min={1} />
                            </Form.Item>

                            <Form.Item name="retired" label={<IntlMessages id="common.retired" />} valuePropName="checked">
                                <Switch />
                            </Form.Item>

                            <Form.Item name="organization_note" label={<IntlMessages id="common.organization_note" />}>
                                <Input.TextArea />
                            </Form.Item>
                        </Card>
                    </Col>

                    <Col xs={24} md={24} lg={12}>
                        <CustomFieldsCard player={player} subEvent={subEvent} intl={intl} />

                        <ProductsCard variants={player.product_variants} subEvent={subEvent} intl={intl} />

                        {subEventHasMedicalCartificate(subEvent) && (
                            <Card
                                title={<IntlMessages id="common.certificate" />}
                                className="gx-card"
                                actions={[
                                    <a onClick={() => setShowCertificateUploadModal(true)} key="upload" type="primary">
                                        <UploadOutlined className="gx-pr-1" />
                                        <IntlMessages id="common.upload" />
                                    </a>,
                                    player.certificate?.path ? (
                                        <a href={getMediaURL(player.certificate?.path)} target="_blank" rel="noreferrer">
                                            <EyeOutlined /> <IntlMessages id="common.view_certificate" />
                                        </a>
                                    ) : null,
                                    player.certificate?.path ? (
                                        <a onClick={onDeleteCertificate} href="#" target="_blank">
                                            <DeleteOutlined /> <IntlMessages id="common.delete" />
                                        </a>
                                    ) : null,
                                ].filter(Boolean)}
                            >
                                <Row>
                                    <Col xs={24} md={12}>
                                        <Form.Item name="certificate_expire_at" label={<IntlMessages id="common.expireAt" />}>
                                            <DatePicker format={DATE_FORMAT} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="certificate_organization_confirm"
                                            label={<IntlMessages id="common.confirmed" />}
                                            valuePropName="checked"
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        )}
                        {subEventHasAthleteCard(subEvent) && (
                            <Card
                                className="gx-card"
                                title={<IntlMessages id="common.athlete_card" />}
                                extra={[
                                    <span>
                                        <AthleteCardValidationTag key="athlete_card_validation" card={player.athlete_card} />
                                    </span>,
                                ]}
                                actions={[
                                    <a
                                        key="verification"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            athleteCardValidations({
                                                variables: {
                                                    players_id: [player.id],
                                                },
                                            })
                                                .then(
                                                    defaultMutationHandler({
                                                        successMessage: intl.formatMessage({ id: 'common.operation_successful' }),
                                                        successCallback: () => {
                                                            refetch();
                                                        },
                                                        errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                                                    })
                                                )
                                                .catch((e) => defaultCatchException(e, intl));
                                        }}
                                    >
                                        {athleteCardValidationLoading ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <span>
                                                <CheckCircleOutlined className="gx-pr-1" />
                                                <IntlMessages id="common.athlete_card_provider_verification" />
                                            </span>
                                        )}
                                    </a>,
                                    <a onClick={() => setShowCardUploadModal(true)} key="upload" type="primary">
                                        <UploadOutlined className="gx-pr-1" />
                                        <IntlMessages id="common.upload" />
                                    </a>,
                                    player.athlete_card?.file_path ? (
                                        <a href={getMediaURL(player.athlete_card?.file_path)} target="_blank" rel="noreferrer">
                                            <IntlMessages id="common.view_athlete_card" />
                                        </a>
                                    ) : null,
                                ].filter(Boolean)}
                            >
                                <Form.Item
                                    rules={subEvent.predefined_fields.athlete_card.required ? [requiredRule(intl)] : null}
                                    name="athlete_card"
                                    label={<IntlMessages id="common.athlete_card" />}
                                >
                                    <Select>
                                        {subEvent.predefined_fields.athlete_card?.options.map((option, index) => (
                                            <Select.Option key={index} value={option.value}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="athlete_card_number"
                                    label={<IntlMessages id="common.athlete_card_number" />}
                                //rules={subEvent.predefined_fields.athlete_card.required ? [requiredRule(intl)] : null}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item name="athlete_card_provider_organization_code" label={<IntlMessages id="common.organization_code" />}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="athlete_card_provider_organization_name" label={<IntlMessages id="common.organization_name" />}>
                                    <Input />
                                </Form.Item>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Form>

            <AssetUploadModal
                title={<IntlMessages id="common.upload_certificate" />}
                visible={showCertificateUploadModal}
                refetch={refetch}
                data={{
                    player_id: player.id,
                }}
                onCancel={() => {
                    setShowCertificateUploadModal(false);
                }}
            />

            <AssetUploadModal
                title={<IntlMessages id="common.upload_athlete_card" />}
                visible={showCardUploadModal}
                refetch={refetch}
                data={{
                    player_id: player.id,
                    type: 'card',
                }}
                onCancel={() => {
                    setShowCardUploadModal(false);
                }}
            />

            <MovePlayerModal
                intl={intl}
                refetch={refetch}
                visible={showMovePlayer}
                player={player}
                subEvent={subEvent}
                onCancel={() => setShowMovePlayer(false)}
                onSuccess={() => {
                    message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                    setShowMovePlayer(false);
                    closeHandler();
                }}
            />

            <DevButton path={__filename} />
        </>
    );
};

export default UpdateSubEventPlayer;
