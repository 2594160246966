import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import React from 'react';
import { SECONDARY_COLOR, SUCCESS_COLOR, DANGER_COLOR } from '../../constants/ThemeSetting';
import IntlMessages from '../../util/IntlMessages';

export const AthleteCardValidationIcon = ({ card }) => {
    const { validation } = card;

    switch (validation) {
        case 1:
            return (
                <Tooltip title={<IntlMessages id={`common.fidal_validation_state_1`} />}>
                    <CloseCircleOutlined className="gx-text-danger" />
                </Tooltip>
            );
        case 2:
            return (
                <Tooltip title={<IntlMessages id={`common.fidal_validation_state_2`} />}>
                    <CheckCircleOutlined className="gx-text-success" />
                </Tooltip>
            );
        case 3:
            return (
                <Tooltip title={<IntlMessages id={`common.fidal_validation_state_3`} />}>
                    <CheckCircleOutlined className="gx-text-warning" />
                </Tooltip>
            );
        default:
            return (
                <Tooltip title={<IntlMessages id={`common.fidal_validation_state_0`} />}>
                    <QuestionCircleOutlined />{' '}
                </Tooltip>
            );
    }
};

const getCardValidationtagColor = (validation) => {
    switch (validation) {
        case 1:
            return DANGER_COLOR;
        case 2:
            return SUCCESS_COLOR;
        case 3:
            return SECONDARY_COLOR;
        default:
            return null;
    }
};

export const AthleteCardValidationTag = ({ card }) => {

    if(!card) return null;

    const { validation } = card;

    if (!validation) return null;

    return (
        <Tag color={getCardValidationtagColor(validation)}>
            <IntlMessages id={`common.fidal_validation_state_${validation}`} />
        </Tag>
    );
};
