import { gql } from '@apollo/client';

export const CREATE_EVENT = gql`
    mutation createEvent($event: CreateEventInput!) {
        createEvent(event: $event)
    }
`;

export const UPDATE_EVENT = gql`
    mutation updateEvent($id: String!, $event: UpdateEventInput!) {
        updateEvent(id: $id, event: $event)
    }
`;

export const DELETE_EVENT = gql`
    mutation deleteEvent($id: String!) {
        deleteEvent(id: $id)
    }
`;

export const DUPLICATE_EVENT = gql`
    mutation duplicateEvent($id: String!) {
        duplicateEvent(id: $id)
    }
`;

export const CREATE_SUB_EVENT = gql`
    mutation createSubEvent($subEvent: CreateSubEventInput!) {
        createSubEvent(subEvent: $subEvent)
    }
`;

export const UPDATE_SUB_EVENT = gql`
    mutation updateSubEvent($id: String!, $subEvent: UpdateSubEventInput!) {
        updateSubEvent(id: $id, subEvent: $subEvent)
    }
`;

export const GENERATE_EVENT_CONTRACT = gql`
    mutation generateEventContract($id: String!, $input: GenerateEventContractInput!) {
        generateEventContract(id: $id, input: $input)
    }
`;

export const SIGN_EVENT_CONTRACT = gql`
    mutation generateEventContract($id: String!) {
        signEventContract(id: $id)
    }
`;

export const RESET_EVENT_CONTRACT = gql`
    mutation resetEventContract($id: String!) {
        resetEventContract(id: $id)
    }
`;

export const UPDATE_EVENT_PAYMENTS = gql`
    mutation updateEventPayments($id: String!, $input: UpdateEventPaymentsInput!) {
        updateEventPayments(id: $id, input: $input)
    }
`;

export const PUBLISH_EVENT = gql`
    mutation publishEvent($id: String!, $confirm: Boolean!) {
        publishEvent(id: $id, confirm: $confirm) {
            warnings {
                message
                payload {
                    name
                    link
                }
            }
            errors {
                message
                payload {
                    name
                    link
                }
            }
            published
        }
    }
`;

export const UNPUBLISH_EVENT = gql`
    mutation unpublishEvent($id: String!) {
        unpublishEvent(id: $id)
    }
`;

export const UPDATE_SUBSCRIPTION_DISCOUNT = gql`
    mutation updateSubscriptionDiscount($id: String!, $input: UpdateSubscriptionDiscountInput!) {
        updateSubscriptionDiscount(id: $id, input: $input)
    }
`;

export const DELETE_SUBSCRIPTION_DISCOUNT = gql`
    mutation deleteSubscriptionDiscount($id: String!) {
        deleteSubscriptionDiscount(id: $id)
    }
`;

export const GENERATE_DISCOUNTS = gql`
    mutation generateDiscounts($input: DiscountGenerationInput!) {
        generateDiscounts(input: $input)
    }
`;

export const DELETE_DISCOUNTS = gql`
    mutation deleteDiscounts($ids: [String!]!) {
        deleteDiscounts(ids: $ids)
    }
`;

export const ADD_EXTRA_FEE = gql`
    mutation addPaymentExtraFee($id: String!, $input: AddPaymentExtraFeeInput!) {
        addPaymentExtraFee(id: $id, input: $input)
    }
`;

export const ADD_TRANSFER_TO_ORGANIZATION = gql`
    mutation addPaymentTranferToOrganization($id: String!, $input: AddPaymentTranferToOrganizationInput!) {
        addPaymentTranferToOrganization(id: $id, input: $input)
    }
`;

export const DELETE_EXTRA_FEE = gql`
    mutation deletePaymentExtraFee($id: String!, $fee_id: String!) {
        deletePaymentExtraFee(id: $id, fee_id: $fee_id)
    }
`;

export const DELETE_TRANSFER_TO_ORGANIZATION = gql`
    mutation deletePaymentTranferToOrganization($id: String!, $transfer_id: String!) {
        deletePaymentTranferToOrganization(id: $id, transfer_id: $transfer_id)
    }
`;

export const SUBSCRIPTION_PAYMENT_REMINDERS = gql`
    mutation subscriptionPaymentReminders($input: [String]!) {
        subscriptionPaymentReminders(input: $input)
    }
`;

export const PLAYER_CERTIFICATE_REMINDERS = gql`
    mutation playerCertificateReminders($players: [String], $sub_event_id: String) {
        playerCertificateReminders(players: $players, sub_event_id: $sub_event_id)
    }
`;

export const PLAYER_CERTIFICATE_CONFIRMS = gql`
    mutation playerCertificateConfirms($input: [String]!) {
        playerCertificateConfirms(input: $input)
    }
`;

export const SUBSCRIPTION_PAYMENT_CONFIRM = gql`
    mutation subscriptionPaymentConfirm($id: String!, $input: PaymentConfirmInput!) {
        subscriptionPaymentConfirm(id: $id, input: $input)
    }
`;

export const RESET_BOLETO_PAYMENT = gql`
    mutation resetBoletoPayment($id: String!, $notify_payer: Boolean) {
        resetBoletoPayment(id: $id, notify_payer: $notify_payer)
    }
`;

export const DELETE_EVENT_PAYMENT = gql`
    mutation deleteEventPayment($id: String!, $notify: Boolean) {
        deleteEventPayment(id: $id, notify: $notify)
    }
`;

export const RESTORE_EVENT_PAYMENT = gql`
    mutation restoreEventPayment($id: String!) {
        restoreEventPayment(id: $id)
    }
`;

export const DELETE_EVENT_PAYMENTS = gql`
    mutation deleteEventPayments($event_id: String!, $payment_type: Int!, $notify: Boolean) {
        deleteEventPayments(event_id: $event_id, payment_type: $payment_type, notify: $notify) {
            success
            fail
        }
    }
`;

export const UPDATE_EVENT_MANAGEMENT = gql`
    mutation updateEventManagement($id: String!, $is_archived: Boolean!) {
        updateEventManagement(id: $id, is_archived: $is_archived)
    }
`;

export const VERIFY_EFI_PAYMENTS = gql`
    mutation verifyEfiPayments($event_id: String!, $payment_type: Int!) {
        verifyEfiPayments(event_id: $event_id, payment_type: $payment_type) {
            verified
            success
            fail
        }
    }
`;

export const PLAYER_RESEND_CONFIRMATION_EMAIL = gql`
    mutation playerResendConfirmationEmail($id: String!) {
        playerResendConfirmationEmail(id: $id)
    }
`;